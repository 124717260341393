import React from 'react';
import { Link } from 'react-router-dom';
import { getPropertyColor, getPropertyLogoFormat } from 'domain/properties';
import colors from 'utils/colors';
import { formatCurrency } from 'utils/number';
import { Button, styled } from '@mui/material';
import IconButton from 'components/shared/button/IconButton';

const Card = styled(Link)({
  display: 'flex',
  width: '100%',
  borderRadius: '4px',
  backgroundColor: colors.main.surface,
  boxShadow: '0px 1px 3px rgba(52, 36, 9, 0.14), 0px 1px 8px rgba(52, 36, 9, 0.2)',
  textDecoration: 'none',
  overflow: 'hidden',
});

const CardMedia = styled('div')({
  minWidth: '36px',
  maxWidth: '36px',
  height: '36px',
});

const PropertyLogo = styled('div')({
  width: '100%',
  height: '100%',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  backgroundSize: 'contain',
});

const DefaultLogo = styled('div')({
  display: 'flex',
  alignItems: 'flex-end',
  width: '100%',
  height: '100%',
  backgroundRepeat: 'no-repeat',
  backgroundPositionY: '8px',
  backgroundPositionX: '4px',
  backgroundSize: 'contain',
});

const CardContent = styled('div')({
  display: 'flex',
  alignItems: 'center',
  width: 'calc(100% - 186px)',
  height: '36px',
  padding: '0px 12px',
});

const CardTitle = styled('div')({
  fontSize: '14px',
  lineHeight: '24px',
  fontWeight: 'bold',
  color: colors.secondary[900],
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
});

const CardActions = styled('div')({
  display: 'flex',
  marginLeft: '8px',
  gap: '6px',
});

const Pill = styled(Button)({
  minWidth: 'unset',
  padding: '2px 4px',
  border: '1px solid',
  borderRadius: '4px',
  fontSize: '10px',
  lineHeight: '16px',
  fontWeight: '600',
  color: colors.main.text,
  textTransform: 'unset',
  fontFamily: 'Mulish',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
});

const CriticalPill = styled(Pill)({
  borderColor: colors.tertiary[900],
  backgroundColor: colors.tertiary[50],
  '&:hover': {
    backgroundColor: colors.tertiary[50],
  },
});

const OpenPill = styled(Pill)({
  borderColor: colors.primary[900],
  backgroundColor: colors.primary[50],
  '&:hover': {
    backgroundColor: colors.primary[50],
  },
});

const OverDuePill = styled(Pill)({
  borderColor: colors.tertiary[900],
  backgroundColor: colors.tertiary[800],
  color: colors.main.surface,
  '&:hover': {
    backgroundColor: colors.tertiary[800],
  },
});

const CardIndicator = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
  justifyContent: 'center',
  width: '150px',
  paddingRight: '8px',
});

const CardIndicatorTitle = styled('div')({
  fontSize: '10px',
  lineHeight: '16px',
  color: colors.main.greyIcon,
});

const CardIndicatorValue = styled('div')({
  fontFamily: 'Lustria',
  fontSize: '18px',
  lineHeight: '20px',
  color: colors.tertiary[900],
});

const SX_ON_DELETE_BUTTON = {
  marginRight: '15px',
  marginTop: '6px',
};

const PropertyCard = ({ property, indicator, onDelete }) => {
  const {
    id,
    name,
    logo,
    critical_items_count: criticalCount,
    open_items_count: openCount,
    over_due_items_count: overDueCount,
  } = property;
  const color = getPropertyColor(property);
  const { margin } = getPropertyLogoFormat(property);

  return (
    <Card key={id} to={`/properties/${id}/dashboard`}>
      <CardMedia
        sx={{
          backgroundColor: color,
        }}
      >
        {logo ? (
          <PropertyLogo
            sx={{
              backgroundImage: `url(${logo.url})`,
              margin: `${margin}px`,
              width: `calc(100% - ${margin * 2}px)`,
              height: `calc(100% - ${margin * 2}px)`,
            }}
          />
        ) : (
          <DefaultLogo />
        )}
      </CardMedia>
      <CardContent>
        <CardTitle>{name}</CardTitle>
        <CardActions>
          {!!criticalCount && <CriticalPill>{criticalCount} Critical</CriticalPill>}
          {!!openCount && <OpenPill>{openCount} Open</OpenPill>}
          {!!overDueCount && <OverDuePill>{overDueCount} Over Due</OverDuePill>}
        </CardActions>
      </CardContent>
      {indicator && (
        <CardIndicator>
          <CardIndicatorTitle>{indicator.name}</CardIndicatorTitle>
          <CardIndicatorValue>{formatCurrency(indicator.value)}</CardIndicatorValue>
        </CardIndicator>
      )}
      {onDelete ? (
        <IconButton
          sx={SX_ON_DELETE_BUTTON}
          stopPropagation
          preventDefault
          icon="delete"
          onClick={() => onDelete()}
        />
      ) : null}
    </Card>
  );
};

export default PropertyCard;
