import React from 'react';
import PropTypes from 'prop-types';
import { Button as ButtonMui, styled } from '@mui/material';
import classnames from 'classnames';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import EditIcon from '@mui/icons-material/ModeEditOutlined';
import AddIcon from '@mui/icons-material/Add';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import DoneIcon from '@mui/icons-material/Done';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { ReactComponent as ConfirmIcon } from 'assets/svg/confirm.svg';
import { ReactComponent as CancelIcon } from 'assets/svg/cancel.svg';
import { ReactComponent as CsvIcon } from 'assets/svg/csv-outline.svg';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ClearIcon from '@mui/icons-material/Clear';
import colors from 'utils/colors';
import PublicIcon from '@mui/icons-material/Public';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import OpenItemIcon from '@mui/icons-material/EventNote';
import SearchIcon from '@mui/icons-material/Search';
import BarChartIcon from '@mui/icons-material/BarChart';
import SaveIcon from '@mui/icons-material/Save';
import Tooltip from 'components/shared/tooltip/Tooltip';
import TuneIcon from '@mui/icons-material/Tune';
import SummarizeIcon from '@mui/icons-material/Summarize';
import ArchiveIcon from '@mui/icons-material/Archive';
import ClearCancelIcon from '@mui/icons-material/DoDisturb';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SettingsIcon from '@mui/icons-material/Settings';
import CloseIcon from '@mui/icons-material/Close';

export const buttonIcons = {
  add: AddIcon,
  delete: DeleteIcon,
  clear: ClearIcon,
  email: EmailOutlinedIcon,
  tick: DoneIcon,
  edit: EditIcon,
  confirm: ConfirmIcon,
  cancel: CancelIcon,
  clearCancel: ClearCancelIcon,
  download: FileDownloadOutlinedIcon,
  upload: FileUploadOutlinedIcon,
  folder: FolderOutlinedIcon,
  back: ArrowBackIcon,
  done: DoneAllIcon,
  upArrow: KeyboardArrowUpIcon,
  downArrow: KeyboardArrowDownIcon,
  public: PublicIcon,
  shared: PeopleOutlineIcon,
  private: LockOutlinedIcon,
  search: SearchIcon,
  csv: CsvIcon,
  openItem: OpenItemIcon,
  barChart: BarChartIcon,
  filter: TuneIcon,
  save: SaveIcon,
  report: SummarizeIcon,
  archive: ArchiveIcon,
  moreVert: MoreVertIcon,
  settings: SettingsIcon,
  close: CloseIcon,
};
export const getIcon = (icon) => buttonIcons[icon] || icon;

export const ButtonControl = styled(ButtonMui)({
  borderRadius: '4px',
  padding: '9px 14px',
  fontSize: '14px',
  lineHeight: '16px',
  fontWeight: '600',
  border: '1px solid',
  fontFamily: 'Mulish',
  textTransform: 'none',
  color: colors.primary[900],

  '&.Mui-disabled': {
    color: colors.main.disabledLabel,
    border: 'none',
  },

  '&.selected': {
    color: colors.tertiary[900],
  },
});

const TransparentButton = styled(ButtonControl)({
  background: 'none',
  border: 'none',
  '&:hover': { background: 'none' },

  '&.selected': {
    backgroundColor: colors.tertiary[900],

    '& .MuiSvgIcon-root': {
      color: colors.main.whiteText,
    },
  },
});

const WhiteButton = styled(ButtonControl)({
  backgroundColor: colors.main.surface,
  borderColor: colors.main.controlBorder,
  color: colors.main.greyIcon,
  '&:hover': { backgroundColor: colors.main.surface },
  '& .MuiSvgIcon-root': {
    color: colors.primary[700],
  },
});

const WhiteGreyButton = styled(ButtonControl)({
  backgroundColor: colors.main.surface,
  borderColor: colors.main.controlBorder,
  '&:hover': { backgroundColor: colors.main.surface },
  '& .MuiSvgIcon-root': {
    color: colors.primary[700],
  },
});

const ClearButton = styled(ButtonControl)({
  backgroundColor: colors.primary[50],
  borderColor: colors.primary[50],
  color: colors.main.text,
  '&:hover': { backgroundColor: colors.primary[50] },
  '&.Mui-disabled': {
    backgroundColor: colors.main.surface,
  },
  '& .MuiSvgIcon-root': {
    color: colors.primary[600],
  },
});

const DarkButton = styled(ButtonControl)({
  backgroundColor: colors.primary[900],
  borderColor: colors.primary[900],
  color: colors.main.surface,
  '&:hover': { backgroundColor: colors.primary[900] },
  '&.Mui-disabled': {
    color: colors.main.surface,
  },
  '& .MuiSvgIcon-root': {
    color: colors.main.surface,
  },
});

const RedButton = styled(ButtonControl)({
  backgroundColor: colors.tertiary[50],
  borderColor: colors.tertiary[50],
  color: colors.tertiary[900],
  '&:hover': { backgroundColor: colors.tertiary[50] },
  '&.Mui-disabled': {
    color: colors.tertiary[900],
  },
  '& .MuiSvgIcon-root': {
    color: colors.tertiary[900],
  },
});

const OverlayButton = styled(ButtonControl)({
  backgroundColor: colors.primary[25],
  borderColor: colors.main.overlayFocused,
  color: colors.primary[900],
  '&:hover': { backgroundColor: colors.primary[25] },
  '&.Mui-disabled': {
    color: colors.primary[900],
  },
  '& .MuiSvgIcon-root': {
    color: colors.primary[900],
  },
});

const AlertButton = styled(ButtonControl)({
  backgroundColor: colors.tertiary[800],
  borderColor: colors.tertiary[900],
  color: colors.main.surface,
  '&:hover': {
    backgroundColor: colors.tertiary[800],
  },
  '&.Mui-disabled': {
    color: colors.main.surface,
  },
  '& .MuiSvgIcon-root': {
    color: colors.main.surface,
  },
});

const FloaterButton = styled(ButtonControl)({
  padding: '16px 20px',
  borderRadius: '8px',
  boxShadow: '0px 4px 5px rgba(52, 36, 9, 0.14), 0px 2px 4px rgba(52, 36, 9, 0.2)',
  backgroundColor: colors.secondary[900],
  borderColor: colors.secondary[900],
  color: colors.main.surface,
  '&:hover': { backgroundColor: colors.secondary[900] },
  '&.Mui-disabled': {
    color: colors.main.surface,
  },
  '& .MuiSvgIcon-root': {
    color: colors.main.surface,
  },
});

const ClearFloaterButton = styled(ButtonControl)({
  padding: '16px 20px',
  borderRadius: '8px',
  backgroundColor: colors.primary[50],
  borderColor: colors.primary[50],
  color: colors.main.text,
  '&:hover': { backgroundColor: colors.primary[50] },
  '&.Mui-disabled': {
    color: colors.main.text,
  },
  '& .MuiSvgIcon-root': {
    color: colors.primary[600],
  },
});

export const buttonVariants = {
  transparent: TransparentButton,
  white: WhiteButton,
  whiteGrey: WhiteGreyButton,
  clear: ClearButton,
  dark: DarkButton,
  red: RedButton,
  overlay: OverlayButton,
  alert: AlertButton,
  floater: FloaterButton,
  clearFloater: ClearFloaterButton,
};

const Button = ({
  id,
  variant,
  className,
  tooltip,
  disabled,
  startIcon,
  endIcon,
  iconSize,
  selected,
  stopPropagation,
  preventDefault,
  sx,
  onClick,
  children,
}) => {
  const Control = buttonVariants[variant];
  const StartIcon = getIcon(startIcon);
  const EndIcon = getIcon(endIcon);

  const handleOnClick = onClick
    ? (e) => {
        if (stopPropagation) e.stopPropagation();
        if (preventDefault) e.preventDefault();
        onClick(e);
      }
    : null;

  return (
    <Tooltip tooltip={tooltip}>
      <Control
        id={id}
        className={classnames(className, {
          selected,
          'with-icon': !!(startIcon || endIcon),
        })}
        disabled={disabled}
        startIcon={!!startIcon && <StartIcon style={{ width: iconSize, height: iconSize }} />}
        endIcon={!!endIcon && <EndIcon />}
        sx={{
          padding: startIcon || endIcon ? '5px 14px' : null,
          ...sx,
        }}
        onClick={handleOnClick}
      >
        {children}
      </Control>
    </Tooltip>
  );
};

Button.propTypes = {
  id: PropTypes.string,
  variant: PropTypes.string,
  className: PropTypes.string,
  tooltip: PropTypes.string,
  disabled: PropTypes.bool,
  startIcon: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  endIcon: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  iconSize: PropTypes.number,
  selected: PropTypes.bool,
  stopPropagation: PropTypes.bool,
  preventDefault: PropTypes.bool,
  sx: PropTypes.shape(),
  onClick: PropTypes.func,
};

Button.defaultProps = {
  id: null,
  className: null,
  variant: 'clear',
  tooltip: null,
  disabled: false,
  startIcon: null,
  endIcon: null,
  iconSize: 24,
  selected: false,
  stopPropagation: false,
  preventDefault: false,
  sx: null,
  onClick: null,
};

export default Button;
