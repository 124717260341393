import React from 'react';
import PropTypes from 'prop-types';
import colors from 'utils/colors';
import Button from './Button';

const IconButton = ({
  id,
  variant,
  className,
  tooltip,
  disabled,
  icon,
  iconSize,
  color,
  selected,
  stopPropagation,
  preventDefault,
  sx,
  onClick,
}) => (
  <Button
    id={id}
    variant={variant}
    className={className}
    tooltip={tooltip}
    disabled={disabled}
    startIcon={icon}
    iconSize={iconSize}
    selected={selected}
    stopPropagation={stopPropagation}
    preventDefault={preventDefault}
    onClick={onClick}
    sx={{
      minWidth: 'unset',
      padding: '0px',
      height: '24px',
      '& .MuiButton-startIcon': {
        margin: '0px',
      },
      '& .MuiSvgIcon-root': {
        color,
        fontSize: '24px!important',
      },
      '&.Mui-disabled': {
        opacity: 0.5,
      },
      ...sx,
    }}
  />
);

IconButton.propTypes = {
  id: PropTypes.string,
  variant: PropTypes.string,
  className: PropTypes.string,
  tooltip: PropTypes.string,
  disabled: PropTypes.bool,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  iconSize: PropTypes.number,
  color: PropTypes.string,
  selected: PropTypes.bool,
  stopPropagation: PropTypes.bool,
  preventDefault: PropTypes.bool,
  sx: PropTypes.shape(),
  onClick: PropTypes.func,
};

IconButton.defaultProps = {
  id: null,
  variant: 'transparent',
  className: null,
  tooltip: null,
  disabled: false,
  color: colors.main.iconColor,
  selected: false,
  stopPropagation: false,
  preventDefault: false,
  sx: {},
  onClick: null,
  iconSize: 24,
};

export default IconButton;
