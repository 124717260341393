import React, { useState } from 'react';
import { styled } from '@mui/material';
import useAbility from 'hooks/shared/useAbility';
import useSnackbar from 'hooks/shared/useSnackBar';
import { useSelector, useDispatch } from 'react-redux';
import { parseNumber } from 'utils/number';
import { deleteSubcategory } from 'services/categories';
import { getProperties, deleteProperty } from 'services/properties';
import useAsyncEffect from 'hooks/shared/useAsyncEffect';
import { savetUserSettngs } from 'slices/auth';
import { setBarCharts } from 'slices/property';
import colors from 'utils/colors';
import SearchableTitle from 'components/shared/search/SearchableTitle';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ExpandMore';
import useCategories from 'hooks/components/useCategories';
import useProperties from 'hooks/components/useProperties';
import Button from 'components/shared/button/Button';
import IconButton from 'components/shared/button/IconButton';
import ConfirmationModal from 'components/shared/modal/ConfirmationModal';
import { pluralize } from 'utils/text';
import { fetchCategories } from 'slices/category';
import PropertyCard from './PropertyCard';
import PropertyBarChart from './PropertyBarChart';

const Title = styled('div')({
  width: '100%',
  fontSize: '24px',
  lineHeight: '36px',
  color: colors.tertiary[900],
  marginTop: '-4px',
  marginBottom: '12px',
});

const CategoryContainer = styled(MuiAccordion)({
  backgroundColor: 'unset',
  color: 'unset',

  '&:before': {
    display: 'none',
  },

  '& .MuiAccordionSummary-root': {
    padding: 0,
    margin: 0,
    minHeight: 'unset',

    '&.Mui-disabled': {
      opacity: 'unset',
    },
  },

  '&.Mui-disabled': {
    backgroundColor: 'unset',
  },
});

const CategoryTitle = styled(MuiAccordionSummary)({
  fontSize: '16px',
  lineHeight: '24px',
  fontWeight: 'bold',
  color: colors.main.blackText,
  '& .MuiAccordionSummary-content': {
    margin: '8px 0px 4px 0px',
  },
});

const CategoryToggle = styled(ArrowForwardIosSharpIcon)({
  color: colors.main.blackText,
});

const Properties = styled(MuiAccordionDetails)({
  display: 'flex',
  flexDirection: 'column',
  gap: '6px',
  width: '100%',
  margin: '0px 0px 8px 0px',
  padding: 0,
});

const SubcategoryTitle = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  cursor: 'pointer',
  height: '36px',
  border: `1px solid ${colors.main.controlBorder}`,
  borderRadius: '4px',
  color: colors.main.greyIcon,
  fontSize: '14px',
  lineHeight: '16px',
  fontWeight: '600',
  padding: '0px 14px',
});

const ADD_SUBCATEGORY_BUTTON = {
  justifyContent: 'flex-start',
};

const PropertiesList = () => {
  const dispatch = useDispatch();
  const ability = useAbility();
  const { showSuccess, showError } = useSnackbar();
  const hasIndicators = ability.can('view', 'GlobalIndicator');
  const canAddSubcategories = ability.can('add', 'Subcategory');
  const canAddProperties = ability.can('add', 'Property');
  const userSettings = useSelector((state) => state.auth.user.settings) || {};
  const closedCategories = userSettings.closed_categories || [];
  const categories = useSelector((state) => state.category.categories);
  const indicators = useSelector((state) => state.propertyIndicator.indicators);
  const barCharts = useSelector((state) => state.property.barCharts);
  const { categoryId, subcategoryId, onShowNewSubcategory, onSetCategoryId, onSetSubcategoryId } =
    useCategories();
  const { onShowNewProperty } = useProperties();
  const [properties, setProperties] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [deletingSubcategory, setDeletingSubcategory] = useState(null);
  const [deletingProperty, setDeletingProperty] = useState(null);
  const hasFilter = (categoryId || !!searchText) && !barCharts;
  const showEmptyCategories = canAddSubcategories && !searchText;
  const canDeleteSubcategories = ability.can('delete', 'Subcategory');
  const canDeleteProperties = ability.can('delete', 'Property');
  const selectedCategory = categoryId
    ? categories?.find((category) => category.id === categoryId)
    : null;
  const selectedSubcategory =
    selectedCategory && subcategoryId
      ? selectedCategory.subcategories.find((subcategory) => subcategory.id === subcategoryId)
      : null;

  useAsyncEffect(
    async () => {
      if (deletingSubcategory || deletingProperty) return;

      const result = await getProperties({
        by_category: categoryId,
        by_subcategory: subcategoryId,
        by_name: searchText || null,
        all: true,
      });
      setProperties(result.records);
    },
    undefined,
    [categoryId, subcategoryId, categories, searchText, deletingSubcategory, deletingProperty]
  );

  if ((hasIndicators && !indicators) || !categories) return null;

  const propertyIndicators = hasIndicators
    ? indicators
        .filter(
          (indicator) =>
            indicator.list_indicator && (!barCharts || categoryId || !indicator.category_indicator)
        )
        .reduce(
          (result, indicator) =>
            indicator.fields.reduce(
              (fieldResult, field) => ({ ...fieldResult, [field.id]: { ...field, indicator } }),
              result
            ),
          {}
        )
    : {};
  const values =
    Object.keys(propertyIndicators).map((key) => parseNumber(propertyIndicators[key].total)) || [];
  const totalPositive = values.reduce((current, value) => current + (value > 0 ? value : 0), 0);
  const totalNegative = values.reduce((current, value) => current + (value < 0 ? value : 0), 0);

  const byCategory = () => {
    const propertiesCategories = {};
    const sorted = [];
    const sortedSubcategories = [];
    properties.forEach((property) => {
      const propertyCategory = property.category.id;
      if (!propertiesCategories[propertyCategory]) {
        sorted.push(property.category);
        propertiesCategories[propertyCategory] = {};
        sortedSubcategories[propertyCategory] = [];
      }

      const propertySubcategory = property.subcategory.id;
      if (!propertiesCategories[propertyCategory][propertySubcategory]) {
        sortedSubcategories[propertyCategory].push(property.subcategory);
        propertiesCategories[propertyCategory][propertySubcategory] = [];
      }

      propertiesCategories[propertyCategory][propertySubcategory].push(property);
    });
    return (
      showEmptyCategories ? (selectedCategory && [selectedCategory]) || categories : sorted
    ).map((category) => {
      const subcategories = showEmptyCategories
        ? (selectedSubcategory && [selectedSubcategory]) || category.subcategories
        : sortedSubcategories[category.id] || [];
      return {
        category,
        subcategories: subcategories.map((subcategory) => ({
          subcategory,
          properties:
            (propertiesCategories[category.id] &&
              propertiesCategories[category.id][subcategory.id]) ||
            [],
        })),
      };
    });
  };

  const byIndicator = () => [
    {
      category: { id: 'all', key: 'all' },
      subcategories: [
        {
          subcategory: { id: 'all', key: 'all' },
          properties: [...properties]
            .filter(
              (property) =>
                propertyIndicators[property.id] &&
                (selectedCategory || parseNumber(propertyIndicators[property.id].total) !== 0)
            )
            .sort(
              (property1, property2) =>
                propertyIndicators[property2.id].total - propertyIndicators[property1.id].total
            ),
        },
      ],
    },
  ];

  const list = () => {
    if (barCharts) return byIndicator();
    return byCategory();
  };

  const getTitle = () => {
    if (selectedSubcategory) return selectedSubcategory.name;
    if (selectedCategory) return selectedCategory.name;
    return 'Portfolio';
  };

  const handleCateogryChange = (changedCategory) => (_, isExpanded) => {
    dispatch(
      savetUserSettngs({
        ...userSettings,
        closed_categories: isExpanded
          ? closedCategories.filter((category) => category !== changedCategory)
          : [...closedCategories, changedCategory],
      })
    );
  };

  const handleToggleBarCharts = () => dispatch(setBarCharts(!barCharts));

  const handleAddSubcategory = (category) => onShowNewSubcategory(category.id);

  const handleAddProperty = (category, subcategory) => {
    onSetCategoryId(category.id);
    onSetSubcategoryId(subcategory.id);
    onShowNewProperty(category.id, subcategory.id);
  };

  const handleSubcategoryClick = (category, subcategory) => {
    onSetCategoryId(category.id);
    onSetSubcategoryId(subcategory.id);
  };

  const handleAskDeleteSubcategory = (category, subcategory) => {
    setDeletingSubcategory({ category, subcategory });
  };

  const handleCancelDeleteSubcategory = () => {
    setDeletingSubcategory(null);
  };

  const handleDeleteSubcategory = async () => {
    try {
      await deleteSubcategory(deletingSubcategory.category.id, deletingSubcategory.subcategory.id);
      showSuccess('Subcategory deleted');
      setDeletingSubcategory(null);
      dispatch(fetchCategories());
    } catch {
      showError('Error deleting subcategory');
    }
  };

  const handleAskDeleteProperty = (property) => {
    setDeletingProperty(property);
  };

  const handleCancelDeleteProperty = () => {
    setDeletingProperty(null);
  };

  const handleDeleteProperty = async () => {
    try {
      await deleteProperty(deletingProperty.id);
      showSuccess('Subcategory deleted');
      setDeletingProperty(null);
      dispatch(fetchCategories());
    } catch {
      showError('Error deleting subcategory');
    }
  };

  const Card = barCharts ? PropertyBarChart : PropertyCard;

  return (
    <>
      <Title>
        <SearchableTitle
          title={getTitle()}
          onChange={setSearchText}
          rightContent={
            hasIndicators ? (
              <IconButton
                icon="barChart"
                selected={barCharts}
                color={colors.main.blackText}
                onClick={handleToggleBarCharts}
              />
            ) : null
          }
        />
      </Title>
      {list().map(({ category, subcategories: categorySubcategories }) => (
        <CategoryContainer
          disableGutters
          elevation={0}
          key={category.id}
          expanded={hasFilter || !closedCategories.includes(category.id)}
          disabled={hasFilter}
          onChange={handleCateogryChange(category.id)}
        >
          {!selectedCategory && !barCharts ? (
            <CategoryTitle expandIcon={!hasFilter ? <CategoryToggle /> : null}>
              {category.name}
            </CategoryTitle>
          ) : null}
          <Properties>
            {categorySubcategories.map(({ subcategory, properties: categoryProperties }) => (
              <React.Fragment key={subcategory.key}>
                {!selectedSubcategory && !barCharts ? (
                  <SubcategoryTitle onClick={() => handleSubcategoryClick(category, subcategory)}>
                    <span>{subcategory.name}</span>
                    {canDeleteSubcategories ? (
                      <IconButton
                        stopPropagation
                        icon="delete"
                        onClick={() => handleAskDeleteSubcategory(category, subcategory)}
                      />
                    ) : null}
                  </SubcategoryTitle>
                ) : null}
                {categoryProperties.map((property) => (
                  <Card
                    key={property.id}
                    property={property}
                    indicator={
                      propertyIndicators[property.id]
                        ? {
                            name: propertyIndicators[property.id].indicator.name,
                            value: propertyIndicators[property.id].total,
                          }
                        : null
                    }
                    total={
                      propertyIndicators[property.id] && propertyIndicators[property.id].total < 0
                        ? totalNegative
                        : totalPositive
                    }
                    onDelete={canDeleteProperties ? () => handleAskDeleteProperty(property) : null}
                  />
                ))}
                {canAddProperties && selectedSubcategory ? (
                  <Button
                    sx={ADD_SUBCATEGORY_BUTTON}
                    startIcon="add"
                    variant="transparent"
                    onClick={() => handleAddProperty(category, subcategory)}
                  >
                    Add Details
                  </Button>
                ) : null}
              </React.Fragment>
            ))}
            {!selectedSubcategory && !barCharts && canAddSubcategories ? (
              <Button
                sx={ADD_SUBCATEGORY_BUTTON}
                startIcon="add"
                variant="transparent"
                onClick={() => handleAddSubcategory(category)}
              >
                Add {pluralize(category.name, 1)}
              </Button>
            ) : null}
          </Properties>
        </CategoryContainer>
      ))}
      {deletingSubcategory && (
        <ConfirmationModal
          title={`${deletingSubcategory.subcategory.name} will be deleted.`}
          description="Are you sure?"
          onCancel={handleCancelDeleteSubcategory}
          onConfirm={handleDeleteSubcategory}
        />
      )}
      {deletingProperty && (
        <ConfirmationModal
          title={`${deletingProperty.name} will be deleted.`}
          description="Are you sure?"
          onCancel={handleCancelDeleteProperty}
          onConfirm={handleDeleteProperty}
        />
      )}
    </>
  );
};

export default PropertiesList;
